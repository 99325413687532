import React, { useEffect, useState } from 'react';
import Client from 'shopify-buy';
import { Button, useToast } from '@chakra-ui/react'
import Navbar from '../../components/NavBar';
import { Link, navigate } from 'gatsby';
import MarketingFooter from '../../components/MarketingFooter';

const Stickers = () => {
  const [products, setProducts] = useState([])
  const [cart, setCart] = useState(null)
  const client = Client.buildClient({
    domain: 'shop.getkodama.com',
    storefrontAccessToken: 'cb2a0e5e90a6e9879259555e6681fffe'
  });
  const toast = useToast();

  useEffect(() => {
    client.product.fetchAll().then((products) => {
      // Do something with the products

      setProducts(products)
      console.log(products[0]);
    });
    const id = localStorage.getItem("cart")
    if (id == null) {
      client.checkout.create().then((checkout) => {
        // Do something with the checkout
        setCart(checkout);
        localStorage.setItem("cart", checkout.id)
        console.log(checkout);
      });
    } else {
      client.checkout.fetch(id).then((checkout) => {
        // Do something with the checkout
        setCart(checkout);
        console.log(checkout);
      });
    }
  }, [])

  const addToCart = (lineItems) => {
    client.checkout.addLineItems(cart.id, lineItems).then((checkout) => {
      setCart(checkout)
      toast({
        title: "Added to cart",
        status: "success",
        duration: "2000",
        isClosable: "true",
      })
    });
  }
  
  return (
      <>
        <div className={'bg-hero-image bg-cover p-1 text-white'}>
          <Navbar /> 
          <div className="container xl:px-40 mt-10 lg:h-33vh mb-8 mx-auto grid grid-cols-1 lg:grid-cols-2 items-top">
              <div className="min-h-96 p-2 w-full">
                  <p className="text-5xl mb-8 font-bold">Stickers for the planet</p>
                  <p className="mt-2 text-lg">Show your impact with Kodama stickers.</p>
              </div>
              <div className="p-2 flex">
                <div className="lg:flex-grow"></div>
                <Button onClick={() => navigate("/stickers/cart")} variant="outline" colorScheme="white" className="flex-grow lg:flex-grow-0 lg:flex-auto">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  { cart && cart.lineItems.reduce((p, c) => p + c.quantity, 0) }
                </Button>
              </div>
          </div> 
        </div>
        <div className="container xl:px-40 mb-5 mt-8 lg:-mt-20vh mx-auto px-4 lg:px-0">
          { products.map(p => (
            <div key={p.id}>
              <div className="grid grid-cols-2 lg:grid-cols-5 gap-4">
                {p.variants.map(v => (
                  <div className="text-center flex flex-col items-center space-y-2">
                    <div className="group relative lg:h-auto hover:bg-white transform hover:scale-105">
                      <img className="h-72 mx-auto lg:shadow-md lg:h-auto hover:opacity-20" key={v.id} src={v.image.src} />
                      <div className="opacity-0 flex group-hover:opacity-100 group-hover:bg-translucent-light absolute top-0 left-0 h-full w-full flex-col items-center justify-center">
                        <div className="font-black text-3xl text-primary-dark">$16.00</div>
                        <Button colorScheme="green" className="hidden group-hover:visible shadow-lg" onClick={() => addToCart([{ variantId: v.id, quantity: 1 }])}>Add to Cart</Button>
                      </div>
                      <div className="mt-1">{p.title} - {v.title}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="container xl:px-40 mb-36 mt-36 mx-auto px-4 lg:px-0">
          <div className="bg-beach-image bg-cover bg-center text-white p-6 rounded-lg shadow-lg">
            <h2 className="text-4xl font-black ">How does it work?</h2>
            <p className="my-4 text-2xl font-bold leading-loose">Carbon offsets are a way to reduce your relative carbon footprint. 
              The offsets fund projects like reforestation, direct carbon removal, or sustainable energy projects. These projects actively remove the amount of CO2 in the atmosphere.
              The stickers are tied back to your offset, and give a way to show the reduction in CO2 you helped create. Our stickers are 2x3in.
            </p>
            {/* <p className="text-2xl underline cursor-pointer"><Link to="/exchange">Learn more about offsetting with Kodama</Link></p> */}
          </div>
        </div>
        <div className="container xl:px-40 mb-36 mt-36 mx-auto px-4 lg:px-0">
            <h2 className="text-4xl font-bold text-primary">Offsets with accountability</h2>
            <p className="mt-4 text-2xl text-primary-dark leading-loose">Buy an offset you can hold, offset your daily driving, or a flight with a sticker. Looking to make your entire business more sustianable? Sign up for Kodama and start tracking your emissions and making efforts to acheive sustainability goals.</p>
            <p className="text-2xl text-primary underline cursor-pointer"><Link to="/exchange">Learn more about offsetting with Kodama</Link></p>
        </div>
        <MarketingFooter />
      </>
  )
};

export default Stickers;
